.Footer-container {
    position: relative;
}

.Footer-container>hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>a>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{
    width: 10rem;
}

.blur-f-1{
    left: 15%;
    bottom: 0;
    width: 26rem;
    height: 15rem;
    filter: 500px;
    background: rgba(255, 115, 0,0.75);
}
.blur-f-2{
    filter: 00px;
    right: 15%;
    bottom: 0;
    width: 26rem;
    height: 15rem;
    background: rgba(255, 0, 0,0.75);
}